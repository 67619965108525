// React imports
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'

// Remix imports

// Third party imports
import get from 'lodash/get'

// Generated imports

// App imports

export default function useCarousel(
  numberOfSlides: number,
  contentContainer: React.RefObject<HTMLElement>,
  interval: number = 4000,
  currentIndex: number = 0,
  noAutoSlide: boolean = false,
  onSlideChange: any = undefined
) {
  const [currentSlide, setSlide] = useState(currentIndex)
  const [isPaused, setIsPaused] = useState(noAutoSlide)
  const width = get(contentContainer, 'current.offsetWidth')
  let left = 0
  if (width) {
    left = width * currentSlide
  }

  const togglePause = useCallback(() => {
    const newValue = !isPaused
    setIsPaused(newValue)
  }, [isPaused])

  const nextSlide = useCallback(() => {
    let nextSlideId = currentSlide + 1
    if (nextSlideId > numberOfSlides - 1) {
      nextSlideId = 0
    }
    setSlide(nextSlideId)
    if (onSlideChange) {
      onSlideChange()
    }
  }, [currentSlide, numberOfSlides])

  const previousSlide = () => {
    let nextSlideId = currentSlide - 1
    if (nextSlideId < 0) {
      nextSlideId = numberOfSlides - 1
    }
    setSlide(nextSlideId)
    if (onSlideChange) {
      onSlideChange()
    }
  }

  useEffect(() => {
    let id: NodeJS.Timeout
    if (!isPaused) {
      id = setTimeout(() => {
        nextSlide()
      }, interval)
    }

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [currentSlide, interval, isPaused, nextSlide, togglePause])

  return {
    currentSlide,
    left,
    nextSlide,
    previousSlide,
    setSlide,
    togglePause,
    pauseSlider: () => setIsPaused(true),
    resumeSlider: () => setIsPaused(false),
  }
}
